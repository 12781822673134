<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      :refName="'StudentScheduleExamTimeReport'"
      size="md"
      :headerText="$t('general.printCertificate')"
      :headerIcon="studentScheduleExamTime.icon"
    >
      <div class="row">
        <CustomInput
          :className="'col-md-12'"
          :id="`sendTo`"
          :value="studentScheduleExamTime.sendTo"
          v-on:changeValue="studentScheduleExamTime.sendTo = $event"
          :title="$t('email')"
          :imgName="'email.svg'"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="generateExam()"
        >
          {{ $t("viewAndSend") }}
        </button>
        <button
          class="btn btn-cancel"
          @click.prevent="closeBottomSheet('StudentScheduleExamTimeReport')"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </CustomBottomSheet>
  </div>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import CustomInput from "./../../../../components/general/CustomInput.vue";
import { STATUS } from "./../../../../utils/constants";
import { viewFileFromServer } from "./../../../../utils/functions";
import apiStudentScheduleExamTimes from "./../../../../api/educational/studentScheduleExamTimes";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    CustomInput,
  },
  props: ["studentScheduleExamTime"],
  methods: {
    async generateExam() {
      this.isLoading = true;
      try {
        let params = {
          token: this.studentScheduleExamTime.studentScheduleExamTimeToken,
          sendTo: this.studentScheduleExamTime.sendTo,
        };
        const response = await apiStudentScheduleExamTimes.generateExam(params);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("StudentScheduleExamTimeReport");
          viewFileFromServer(response.data.reportPath);
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>
